import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import actions from "../../../common/actions";
import Loader from "../../../common/components/Loader";

const FilterForm = ({ pushFilters, setPushFilters }) => {
    let userRole = localStorage.getItem("user_role");

    const dispatch = useDispatch();
    const filterDropdownData = useSelector(state => state.commonReducer.getFilterDropdownDataResponse);

    const [filterState, setFilterState] = useState({
        selectedDropdown: null,
        filterDropdownList: [],
        searchQuery: ""
    });

    const wrapperRef = useRef(null);

    const dropdownFilters = [
        { key: "consultancies", isVisible: userRole === "super_admin", paramKey: "consultancy_ids", label: "Consultancies" },
        { key: "clients", isVisible: userRole === "super_admin" || userRole === "consultancy_user", paramKey: "client_ids", label: "Clients" },
        { key: "buildings", isVisible: true, paramKey: "building_ids", label: "Buildings" }
    ];

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setFilterState(prev => ({ ...prev, selectedDropdown: null }));
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const toggleDropdown = key => {
        const { selectedDropdown } = filterState;

        if (selectedDropdown === key) {
            setFilterState(prev => ({ ...prev, selectedDropdown: null }));
            return;
        }

        dispatch(actions.getFilterDropdownData(pushFilters, key));

        setFilterState(prev => ({
            ...prev,
            selectedDropdown: key,
            filterDropdownList: []
        }));
    };

    useEffect(() => {
        if (filterDropdownData.success && filterState.selectedDropdown) {
            setFilterState(prev => ({
                ...prev,
                filterDropdownList: filterDropdownData[filterState.selectedDropdown] || []
            }));
        }
    }, [filterDropdownData]);

    const handleFilterChange = (paramKey, id) => {
        const updatedFilters = { ...pushFilters };

        if (id === "all") {
            const allIds = filterState.filterDropdownList.map(item => item.id);
            updatedFilters[paramKey] = updatedFilters[paramKey]?.length === allIds.length ? [] : allIds;
        } else {
            updatedFilters[paramKey] = updatedFilters[paramKey] || [];
            if (updatedFilters[paramKey].includes(id)) {
                updatedFilters[paramKey] = updatedFilters[paramKey].filter(item => item !== id);
            } else {
                updatedFilters[paramKey].push(id);
            }
        }
        if (paramKey === "consultancy_ids") {
            updatedFilters["client_ids"] = [];
            updatedFilters["building_ids"] = [];
        } else if (paramKey === "client_ids") {
            updatedFilters["building_ids"] = [];
        }

        setPushFilters(updatedFilters);
    };

    const handleClear = paramKey => {
        const updatedFilters = { ...pushFilters, [paramKey]: [] };
        setPushFilters(updatedFilters);
    };

    const handleSearchChange = event => {
        const query = event.target.value;
        setFilterState(prev => ({
            ...prev,
            searchQuery: query
        }));
    };

    const getFilteredDropdownList = () => {
        const { filterDropdownList, searchQuery } = filterState;
        if (!searchQuery?.trim()) return filterDropdownList;
        return filterDropdownList.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const options = getFilteredDropdownList();

    return (
        <div className="hd-top master-filter-container filter-psh-activity" ref={wrapperRef}>
            <ReactTooltip effect="solid" />
            <div className="collapse show" id="masterFilters">
                <div className="lft-element drop-fil">
                    {dropdownFilters.map(
                        filter =>
                            filter.isVisible && (
                                <div key={filter.key} className={`dropdown show ${pushFilters?.[filter.paramKey]?.length > 0 ? "filtered" : ""}`}>
                                    <button className="btn btn-secondary dropdown-toggle" type="button" onClick={() => toggleDropdown(filter.key)}>
                                        {filter.label} <i className="d-arrow"></i>
                                    </button>
                                    {filterState.selectedDropdown === filter.key && (
                                        <div className="dropdown-menu show">
                                            <div className="dropdown-item d-flex justify-content-between">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={`${filter.key}-select-all`}
                                                        checked={
                                                            filterState.filterDropdownList.length &&
                                                            pushFilters[filter.paramKey]?.length === filterState.filterDropdownList.length
                                                        }
                                                        onChange={() => handleFilterChange(filter.paramKey, "all")}
                                                    />
                                                    <label className="custom-control-label" htmlFor={`${filter.key}-select-all`}>
                                                        Select All
                                                    </label>
                                                </div>
                                                <button className="btn clear-btn" onClick={() => handleClear(filter.paramKey)}>
                                                    Clear
                                                </button>
                                            </div>
                                            <hr />
                                            {/* <div className="dropdown-item">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                    value={filterState.searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                            <hr /> */}

                                            {filterDropdownData.loading ? (
                                                <div className="dropdown-item">
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <div className="drp-scroll">
                                                    {options?.length ? (
                                                        options.map(item => (
                                                            <div key={item.id} className="dropdown-item">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`${filter.key}-${item.id}`}
                                                                        checked={pushFilters[filter.paramKey]?.includes(item.id)}
                                                                        onChange={() => handleFilterChange(filter.paramKey, item.id)}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`${filter.key}-${item.id}`}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="dropdown-item">No Data</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilterForm;
